import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { VaultProvider } from './context';
import { Complete } from './pages/complete';
import { Details } from './pages/details';
import { Files } from './pages/files';
import { Home } from './pages/home';
import { Review } from './pages/review';
import { Sorry } from './pages/sorry';
import { Time } from './pages/time';
import { Welcome } from './pages/welcome';
import { routes } from './routes';

import { Container } from '~atoms/container';
import { PrivateRoute } from '~atoms/private-route';
import { Layout } from '~organisms/layout';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

/**
 *
 */
export const App: FC = () => (
  <VaultProvider>
    <BrowserRouter>
      <Layout>
        <Container element="main">
          <Routes>
            <Route
              path={routes.COMPLETE}
              element={<PrivateRoute>{Complete}</PrivateRoute>}
            />
            <Route
              path={routes.REVIEW}
              element={
                <PrivateRoute>
                  <Review />
                </PrivateRoute>
              }
            />
            <Route
              path={routes.FILES}
              element={
                <PrivateRoute>
                  <Files />
                </PrivateRoute>
              }
            />
            <Route
              path={routes.TIME}
              element={
                <PrivateRoute>
                  <Time />
                </PrivateRoute>
              }
            />
            <Route
              path={routes.DETAILS}
              element={
                <PrivateRoute>
                  <Details />
                </PrivateRoute>
              }
            />
            <Route
              path={routes.WELCOME}
              element={
                <PrivateRoute>
                  <Welcome />
                </PrivateRoute>
              }
            />
            <Route path={routes.SORRY} element={<Sorry />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Container>
      </Layout>
    </BrowserRouter>
  </VaultProvider>
);
