import { HashProgress, hashFile } from './hash';
import { HashWorkerResponse } from './worker';

/**
 * Helper function to hash the file, uses a webworker if available
 * @param file
 * @param progressFn
 * @returns
 */
export async function doHash(
  file: File,
  progressFn: (progressEvent: HashProgress) => void
) {
  if (!window.Worker) {
    console.log('*** Using main thread to hash file ***');
    return hashFile(file, progressFn);
  }

  // @ts-expect-error - import.meta doesn't resolve for us, but in webpack it does
  const worker = new Worker(new URL('./worker.ts', import.meta.url));
  console.log('*** Using worker to hash file ***');
  return new Promise<string>((resolve, reject) => {
    worker.onmessage = (event) => {
      const data = event.data as HashWorkerResponse;
      if (data.type === 'progress') {
        progressFn(data);
      } else {
        resolve(data.hash);
      }
    };

    worker.onerror = (event) => {
      event.preventDefault();
      console.log('error');
      console.log(event);
      reject(event);
      worker.terminate();
    };

    worker.postMessage({ file });
  });
}
