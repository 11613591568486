import loadable from '@loadable/component';

import { Loading } from '../../molecules';

/**
 *
 */
export const Files = loadable(
  () =>
    import(
      /* webpackChunkName: "files" */
      './files'
    ),
  {
    fallback: Loading,
    resolveComponent: (components) => components.Files
  }
);
