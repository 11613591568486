import type { ReactElement } from 'react';

import styles from './complete.module.scss';

import { Card } from '~atoms/card';

/**
 *
 */
export const Complete: ReactElement = (
  <Card className={styles.complete}>
    <h2>Submission Complete</h2>
    <p>Thank you for taking the time to submit the requested footage.</p>
  </Card>
);
