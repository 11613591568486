import { FC, Fragment, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../context';
import { routes } from '../../routes';

interface PrivateRouteProps {
  readonly children: ReactNode;
}

/**
 *
 * @param props
 */
export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const [auth] = useAuth();

  if (!auth.isAuthenticated) {
    return <Navigate to={routes.SORRY} />;
  }

  return <Fragment>{props.children}</Fragment>;
};
