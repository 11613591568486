import type { FC, ReactNode } from 'react';

import styles from './button-bar.module.scss';

/**
 *
 * @param props
 */
export const ButtonBar: FC<{ children: ReactNode }> = (props) => (
  <div className={styles.buttonBar}>{props.children}</div>
);
