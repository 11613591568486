import type { FC, ReactNode } from 'react';

interface CardProps {
  readonly children: ReactNode;
  readonly className?: string;
}

/**
 *
 * @param props
 */
export const Card: FC<CardProps> = (props) => {
  const classNames = ['vault-card'];

  if (props.className) {
    classNames.push(props.className);
  }

  return <div className={classNames.join(' ')}>{props.children}</div>;
};
