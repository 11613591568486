import axios, { AxiosResponse, Method } from 'axios';
import { useState } from 'react';

import { captureError } from '../../lib/sentry';
import { useAuth } from '../context';
import { Auth } from '../types';

type ApiConfig<ReqBody = any> = {
  auth?: Auth;
  data?: ReqBody;
  method: Method;
  returnError?: boolean;
  url: string;
};

/**
 *
 */
export const useAxios = (): {
  error: boolean;
  callApi: <ReqBody = any, ResBody = any>(
    config: ApiConfig<ReqBody>
  ) => Promise<AxiosResponse<ResBody>>;
} => {
  const [error, setError] = useState<boolean>(false);
  const [auth] = useAuth();

  const callApi = <ReqBody = any, ResBody = any>(
    config: ApiConfig<ReqBody>
  ) => {
    const { data, method, url, returnError } = config;

    const headers = {
      Authorization: 'Bearer ' + (config.auth?.token || auth.token)
    };

    return new Promise<AxiosResponse<ResBody>>((resolve, reject) => {
      axios
        .request({ data, headers, method, url })
        .then((response: AxiosResponse<ResBody>) => {
          setError(false);
          resolve(response);
        })
        .catch((error) => {
          captureError(error, { data });
          setError(true);

          if (returnError) {
            reject(error);
          }
        });
    });
  };

  return { callApi, error };
};
