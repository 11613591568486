import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import styles from './button.module.scss';

interface LinkButtonProps {
  readonly children: ReactNode;
  readonly href: string;
  readonly theme?: 'primary' | 'secondary';
}

export const LinkButton: FC<LinkButtonProps> = (props) => (
  <Link
    to={props.href}
    className={[
      styles.button,
      props.theme === 'secondary'
        ? styles.secondaryButton
        : styles.primaryButton
    ].join(' ')}
  >
    {props.children}
  </Link>
);
