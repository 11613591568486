import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import styles from './sorry.module.scss';

import { Card } from '~atoms/card';

/**
 *
 */
export const Sorry: FC = () => {
  const location = useLocation();
  const expiredToken = location.state?.expiredToken;
  return (
    <Card className={styles.sorry}>
      <h2>
        Sorry,
        {expiredToken
          ? ' it looks like this vault request has expired'
          : ' there seems to be an error'}
      </h2>
      <p>
        {expiredToken
          ? 'Please contact the Police officer who sent you the original request to obtain a new link or alternatively '
          : 'Please use the link in the email you were sent by Police, or alternatively '}
        contact{' '}
        <a href="mailto:support@safercities.com">support@safercities.com</a> for
        support.
      </p>
    </Card>
  );
};
