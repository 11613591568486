import axios, { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import type { FC } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { captureError } from '../../../lib/sentry';
import { useAuth } from '../../context';
import { GET_CREDENTIALS } from '../../endpoints';
import { routes } from '../../routes';

import { Card } from '~atoms/card';
import { Loading } from '~molecules/loading';

/**
 *
 */
export const Home: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_auth, setAuth] = useAuth();

  const navigate = useNavigate();
  const { search } = useLocation();

  useEffect(() => {
    const token = new URLSearchParams(search)?.get('token');

    if (!token) {
      return navigate(routes.SORRY);
    }

    axios
      .get(GET_CREDENTIALS, { headers: { Authorization: 'Bearer ' + token } })
      .then((response: AxiosResponse): void => {
        setAuth({ isAuthenticated: true, signature: response.data, token });
        navigate(routes.WELCOME);
      })
      .catch((error: AxiosError) => {
        setAuth({
          isAuthenticated: false,
          signature: undefined,
          token: undefined
        });

        if (error.response?.status !== 401) {
          captureError(error);
        }

        navigate(routes.SORRY, {
          state: { expiredToken: error.response?.status === 401 }
        });
      });
  }, [search]);

  return <Card>{Loading}</Card>;
};
