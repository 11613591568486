import prettyBytes from 'pretty-bytes';
import prettyMs from 'pretty-ms';
import { Fragment, type FC } from 'react';

import styles from './upload-file.module.scss';

import { InfoIcon } from '~/components/atoms/info-icon';
import type { ProgressType, UploadFileProps } from '~organisms/upload/types';

const determineMillisecondsRemaining = (
  size: number,
  progress: number,
  start: number
): number => {
  if (!progress) {
    return 0;
  }

  const amountDownloaded = (progress / 100) * size;
  const remaining = size - amountDownloaded;
  const timeTakenSoFar = Date.now() - start;

  return remaining / (amountDownloaded / timeTakenSoFar);
};

const getEstimateText = (
  size: number,
  progress: number,
  start: number,
  type: ProgressType
): string => {
  const estimate = determineMillisecondsRemaining(size, progress, start);
  let stepNumber: number;
  let stepName: string;

  switch (type) {
    case 'hash':
      stepNumber = progress === 0 ? 1 : 2;
      stepName =
        progress === 0 ? 'Waiting for hashing to start' : 'Calculating Hash';
      break;
    case 'upload':
      stepNumber = progress === 0 ? 3 : 4;
      stepName =
        progress === 0
          ? 'In queue, waiting for upload to start'
          : 'Uploading File';
      break;
    default:
      stepNumber = 0;
      stepName = '';
  }

  const remainingTime = estimate
    ? ` - Estimate: ${prettyMs(estimate, { compact: true })} remaining`
    : '';

  const estimateText =
    type === 'zip'
      ? `Zipping${remainingTime}`
      : `Step ${stepNumber}/4 (${stepName})${remainingTime}`;

  return estimateText;
};

const getStatusInformation = (error: boolean, progress: number): Array<any> => {
  if (error) {
    return [styles.statusError, 'Failed'];
  }

  if (progress === 100) {
    return [styles.statusSuccess, 'Complete'];
  }

  return [styles.statusActive, ''];
};

const ZIP_REGEX = /^Vault-Upload-\d{13}\.zip$/;

/**
 *
 * @param props
 */
export const UploadFile: FC<UploadFileProps> = (props) => {
  const estimateText = getEstimateText(
    props.size,
    props.progress,
    props.start,
    props.type
  );
  const [statusStyle, statusText] = getStatusInformation(
    props.error,
    props.progress
  );

  const isZipped = Boolean(props.name.match(ZIP_REGEX));

  return (
    <li>
      <div className={styles.status + ' ' + statusStyle} />
      <div className={styles.file}>
        <strong>{props.name}</strong>
        <span className={styles.muted}> {statusText}</span>
        <span>{prettyBytes(props.size)}</span>
      </div>
      {props.error ? (
        <Fragment>
          {isZipped && (
            <div className={styles.zipped}>
              <InfoIcon />
              Upload was automatically zipped as it contained folders
            </div>
          )}
          <div className={styles.error}>{props.message}</div>
        </Fragment>
      ) : (
        <Fragment>
          {isZipped && (
            <div className={styles.zipped}>
              <InfoIcon />
              {props.type === 'zip'
                ? 'Please wait while files are getting zipped'
                : 'Upload was automatically zipped as it contained folders'}
            </div>
          )}
          {props.type !== 'zip' && (
            <div className={styles.muted}>
              Hash: {props.hash === 'unknown' ? 'Waiting...' : props.hash}
            </div>
          )}
          {props.progress < 100 && (
            <div>
              <div
                aria-valuenow={props.progress}
                className={styles.progress}
                role="progressbar"
              >
                <div
                  className={styles.working}
                  style={{ width: props.progress + '%' }}
                />
              </div>
              <div className={styles.upload}>
                <span className={styles.estimate}>{estimateText}</span>
                <span>{props.progress.toFixed(0)}%</span>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </li>
  );
};
