import { createElement, ReactNode } from 'react';
import type { FC } from 'react';

interface ContainerProps {
  readonly children: ReactNode;
  readonly element?: string;
}

/**
 *
 * @param props
 */
export const Container: FC<ContainerProps> = (props) => {
  return createElement(
    props.element || 'div',
    { className: 'vault-container' },
    props.children
  );
};
