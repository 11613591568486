import loadable from '@loadable/component';

import { Loading } from '../../molecules';

/**
 *
 */
export const Details = loadable(
  () =>
    import(
      /* webpackChunkName: "details" */
      './details'
    ),
  {
    fallback: Loading,
    resolveComponent: (components) => components.Details
  }
);
