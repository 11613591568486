import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { FormikHelpers } from 'formik';

import { PUT_DETAILS, PUT_STATUS } from '../endpoints';
import { Status } from '../types';
import type { Details as DetailsForm } from '../types';

import { useAxios } from '.';

interface DetailsResponseSuccess {
  success: true;
}

interface DetailsResponseFailure {
  success: false;
  errors: Record<string, string>;
}

type DetailsResponse = DetailsResponseSuccess | DetailsResponseFailure;

const formatDob = (dob: string) => {
  dayjs.extend(customParseFormat);
  return dayjs(dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
};

export const useUpdateDetails = () => {
  const { error, callApi } = useAxios();

  const updateDetails = async (
    values: DetailsForm,
    actions: FormikHelpers<DetailsForm>
  ) => {
    actions.setSubmitting(true);

    try {
      const response = await callApi<DetailsForm, DetailsResponse>({
        data: { ...values, dob: formatDob(values.dob) },
        method: 'PUT',
        url: PUT_DETAILS
      });

      if (response.data.success === false) {
        for (const [key, value] of Object.entries(response.data.errors)) {
          actions.setFieldError(key, value);
        }
      }

      actions.setSubmitting(false);

      return response;
    } catch (error) {
      console.error('Error occurred while updating details:', error);
      throw error;
    }
  };

  const completeStatus = async () => {
    try {
      const response = await callApi({
        data: { status: Status.COMPLETE },
        method: 'PUT',
        url: PUT_STATUS
      });

      return response;
    } catch (error) {
      console.error('Error occurred while updating status:', error);
      throw error;
    }
  };

  return { completeStatus, error, updateDetails };
};
