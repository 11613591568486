import { createSHA256 } from 'hash-wasm';

const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB

export interface HashProgress {
  loaded: number;
  total: number;
}

/**
 *
 * @param chunk
 */
function readAsUint8(chunk: Blob): Promise<Uint8Array> {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();

    fr.onload = (evt) => {
      if (
        evt.target?.error ||
        !evt.target?.result ||
        !(evt.target.result instanceof ArrayBuffer)
      ) {
        return reject(evt.target?.error ?? new Error('Unable to load chunk'));
      }
      try {
        const result = new Uint8Array(evt.target.result);
        return resolve(result);
      } catch (e) {
        return reject(e);
      }
    };

    fr.readAsArrayBuffer(chunk);
  });
}

/**
 *
 * @param file
 * @param progressFn
 * @returns
 */
export async function hashFile(
  file: File,
  progressFn: (progressEvent: HashProgress) => void
): Promise<string> {
  const hasher = await createSHA256();

  let start = 0;
  while (start < file.size) {
    const end = Math.min(start + CHUNK_SIZE, file.size);
    const chunk = file.slice(start, end);

    const part = await readAsUint8(chunk);
    hasher.update(part);

    progressFn({
      loaded: start,
      total: file.size
    });

    start = end;
  }

  return hasher.digest('hex');
}
