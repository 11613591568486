import { Fragment, useEffect } from 'react';
import type { FC } from 'react';

import { useUpload } from '../../context';
import { routes } from '../../routes';
import { Details } from '../details';

import styles from './welcome.module.scss';

import { LinkButton } from '~atoms/button';
import { ButtonBar } from '~atoms/button-bar';
import { Card } from '~atoms/card';

/**
 *
 */
export const Welcome: FC = () => {
  const [upload] = useUpload();

  useEffect(() => Details.preload(), []);

  return (
    <Fragment>
      <Card className={styles.start}>
        <p>Kia ora,</p>
        <p>
          Welcome to the SaferCity vault, used to provide Police with CCTV
          footage they have requested from you.
        </p>
        <p>
          Please follow these steps carefully, as it is important the
          information that you provide is accurate and true.
        </p>
        <p>
          Police will use the name, date of birth and contact number you provide
          to contact you about your upload(s) if required. You will receive a
          copy of the details you have entered at the end of this process.
        </p>
        <p>
          <strong>
            Your personal information is kept securely and in compliance with
            the Privacy Act 2020. Your upload(s) will be used to assist in the
            detection and prevention of crime.
          </strong>
        </p>
        {Boolean(upload.message) && (
          <p className={styles.message}>
            <b>Message from Police:</b>
            <br />
            {upload.message}
          </p>
        )}
        {upload.start && upload.end && (
          <p>
            <u>
              A time and date to export footage has been requested. The exact
              time and date to export will be calculated in the following steps.
            </u>
          </p>
        )}
      </Card>
      <ButtonBar>
        <LinkButton href={routes.DETAILS}>Get started</LinkButton>
      </ButtonBar>
    </Fragment>
  );
};
