import * as Yup from 'yup';

import { commentDetailsLength } from '~/components/utils';

export const DetailsSchema = Yup.object().shape({
  businessName: Yup.string()
    .notRequired()
    .max(255, 'Business Name is too long'),
  city: Yup.string()
    .required('City is required')
    .max(100, 'City is too long')
    .min(3, 'City is too short'),
  comment: Yup.string()
    .notRequired()
    .max(commentDetailsLength, 'Comment is too long'),
  dob: Yup.string()
    .required('Date of birth is required')
    .matches(
      /^((0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/[12]\d{3})$/,
      'Invalid date. Please use the dd/mm/yyyy format'
    ),
  email: Yup.string()
    .required('Email is required')
    .max(255, 'Email is too long')
    .email('Invalid email address'),
  name: Yup.string()
    .required('Name is required')
    .max(255, 'Name is too long')
    .min(3, 'Name is too short'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .max(16, 'Phone number is too long'),
  streetAddress: Yup.string()
    .required('Street address is required')
    .max(255, 'Street Address is too long')
    .min(3, 'Street address is too short'),
  suburb: Yup.string()
    .required('Suburb is required')
    .max(100, 'Suburb is too long')
    .min(3, 'Suburb is too short')
});
