import { useField } from 'formik';
import { useCallback, useState } from 'react';
import type { FocusEvent, FC } from 'react';

import styles from './input.module.scss';

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  readonly id: string;
  readonly label: string;
  readonly name: string;
  readonly placeholder?: string;
}

/**
 *
 * @param props
 */
export const Input: FC<InputProps> = (props) => {
  const [input, meta] = useField<string>(props.name);

  const [isFocused, setFocused] = useState<boolean>(false);

  const onBlur = useCallback((event: FocusEvent<HTMLInputElement>): void => {
    setFocused(false);
    input.onBlur(event);
  }, []);

  const onFocus = useCallback((): void => {
    setFocused(true);
  }, []);

  const inputClassNames = [styles.input];

  const { id, label, placeholder, ...inputProps } = props;

  const labelClassNames = [styles.label];

  if (meta.value) {
    labelClassNames.push(styles.labelHasValue);
  }

  if (isFocused) {
    inputClassNames.push(styles.inputFocused);
    labelClassNames.push(styles.labelHasValue);
    labelClassNames.push(styles.labelFocused);
  } else if (meta.touched && meta.error) {
    inputClassNames.push(styles.inputError);
    labelClassNames.push(styles.labelError);
  }

  return (
    <label className={labelClassNames.join(' ')} htmlFor={id}>
      <span>{label}</span>
      <input
        className={inputClassNames.join(' ')}
        id={id}
        {...input}
        {...inputProps}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={isFocused ? placeholder : undefined}
      />
      {meta.touched && meta.error ? (
        <div className={[styles.help, styles.error].join(' ')}>
          {meta.error}
        </div>
      ) : null}
    </label>
  );
};
