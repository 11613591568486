import type { FC } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { routes } from '../../../routes';

import { VGridLogo } from './VGridLogo';

import { Container } from '~atoms/container';

const NAV_ACTIVE = 'vault-nav-active';
const NAV_DISABLED = 'vault-nav-disabled';

/**
 *
 * @param props
 */
export const Header: FC = () => {
  const isStart = useMatch({ path: routes.WELCOME });
  const isDetails = useMatch({ path: routes.DETAILS });
  const isTime = useMatch({ path: routes.TIME });
  const isFiles = useMatch({ path: routes.FILES });
  const isReview = useMatch({ path: routes.REVIEW });
  const isComplete = useMatch({ path: routes.COMPLETE });
  const isSorry = useMatch({ path: routes.SORRY });

  const classNames: Array<string | undefined> = [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined
  ];

  if (isComplete || isSorry) {
    classNames[0] = NAV_DISABLED;
    classNames[1] = NAV_DISABLED;
    classNames[2] = NAV_DISABLED;
    classNames[3] = NAV_DISABLED;
    classNames[4] = NAV_DISABLED;
  } else if (isStart) {
    classNames[0] = NAV_ACTIVE;
    classNames[1] = NAV_DISABLED;
    classNames[2] = NAV_DISABLED;
    classNames[3] = NAV_DISABLED;
    classNames[4] = NAV_DISABLED;
  } else if (isDetails) {
    classNames[1] = NAV_ACTIVE;
    classNames[2] = NAV_DISABLED;
    classNames[3] = NAV_DISABLED;
    classNames[4] = NAV_DISABLED;
  } else if (isTime) {
    classNames[2] = NAV_ACTIVE;
    classNames[3] = NAV_DISABLED;
    classNames[4] = NAV_DISABLED;
  } else if (isFiles) {
    classNames[3] = NAV_ACTIVE;
    classNames[4] = NAV_DISABLED;
  } else if (isReview) {
    classNames[4] = NAV_ACTIVE;
  }

  return (
    <header className="vault-header">
      <Container>
        <nav>
          <img
            className="vault-policeLogo"
            src={require('./logo.png')}
            alt="New Zealand Police - Nga Pirihimana O Aotearoa"
          />
          <VGridLogo className="vgrid-brand" />
        </nav>
      </Container>
      <div className="vault-subHeader">
        <Container>
          <nav>
            <h1>Upload files</h1>
            <ul>
              <li className={classNames[0]}>
                <Link to={routes.WELCOME}>Start</Link>
              </li>
              <li className={classNames[1]}>
                <Link to={routes.DETAILS}>Details</Link>
              </li>
              <li className={classNames[2]}>
                <Link to={routes.TIME}>Time</Link>
              </li>
              <li className={classNames[3]}>
                <Link to={routes.FILES}>Files</Link>
              </li>
              <li className={classNames[4]}>
                <Link to={routes.REVIEW}>Review</Link>
              </li>
            </ul>
          </nav>
        </Container>
      </div>
    </header>
  );
};
