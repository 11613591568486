import { useField } from 'formik';
import { useCallback, useState } from 'react';
import type { FocusEvent, FC } from 'react';

import styles from './input.module.scss';

import { commentDetailsLength } from '~/components/utils';

interface InputProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  readonly id: string;
  readonly label: string;
  readonly name: string;
  readonly placeholder?: string;
}

/**
 *
 * @param props
 */
export const Textarea: FC<InputProps> = (props) => {
  const [textarea, meta] = useField<string>(props.name);

  const [isFocused, setFocused] = useState<boolean>(false);

  const onBlur = useCallback((event: FocusEvent<HTMLTextAreaElement>): void => {
    setFocused(false);
    textarea.onBlur(event);
  }, []);

  const onFocus = useCallback((): void => {
    setFocused(true);
  }, []);

  const inputClassNames = [styles.input];

  const { id, label, placeholder, ...inputProps } = props;

  const labelClassNames = [styles.label];

  if (meta.value) {
    labelClassNames.push(styles.labelHasValue);
  }

  if (isFocused) {
    inputClassNames.push(styles.inputFocused);
    labelClassNames.push(styles.labelHasValue);
    labelClassNames.push(styles.labelFocused);
  } else if (meta.touched && meta.error) {
    inputClassNames.push(styles.inputError);
    labelClassNames.push(styles.labelError);
  }

  return (
    <label className={labelClassNames.join(' ')} htmlFor={id}>
      <span>{label}</span>
      <textarea
        className={inputClassNames.join(' ')}
        id={id}
        {...textarea}
        {...inputProps}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={isFocused ? placeholder : undefined}
        rows={4}
      />
      <div>
        {meta.touched && meta.error && (
          <div className={[styles.help, styles.error].join(' ')}>
            {meta.error}
          </div>
        )}
        <small
          className={
            meta.touched && meta.error
              ? [styles.help, styles.error].join(' ')
              : ''
          }
        >
          {meta.value?.length}/{commentDetailsLength}
        </small>
      </div>
    </label>
  );
};
