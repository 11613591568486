import type { FC, ReactNode } from 'react';

import styles from './sub-heading.module.scss';

/**
 *
 * @param props
 */
export const SubHeading: FC<{ readonly children: ReactNode }> = (props) => (
  <h2 className={styles.subHeading}>{props.children}</h2>
);
