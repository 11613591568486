import type { ReactElement } from 'react';

/**
 *
 */
export const Loading: ReactElement = (
  <div className="vault-loading">
    <div>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
      <span>&nbsp;</span>
    </div>
  </div>
);
