import { FC } from 'react';

export const InfoIcon: FC = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      display: 'inline-block',
      marginRight: '0.25rem',
      marginTop: '-0.125rem',
      verticalAlign: 'middle'
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM9 4V6H7V4H9ZM9 7V11H10V12H6V11H7V8H6V7H9Z"
      fill="#1976d2"
    />
  </svg>
);
