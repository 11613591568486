import type { FC } from 'react';

import styles from './button.module.scss';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  readonly theme?: 'primary' | 'secondary';
  readonly type?: 'reset' | 'submit';
}

/**
 *
 * @param props
 */
export const Button: FC<ButtonProps> = (props) => (
  <button
    className={[
      styles.button,
      props.theme === 'secondary'
        ? styles.secondaryButton
        : styles.primaryButton
    ].join(' ')}
    {...props}
  />
);
