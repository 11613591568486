import axios from 'axios';
import pLimit from 'p-limit';
import { FileWithPath } from 'react-dropzone';

import { useAuth } from '../context';
import { POST_FILES } from '../endpoints';
import { doHash } from '../organisms/upload/hasher';
import { FileUploadReducerDispatch } from '../organisms/upload/reducer';
import { Signature, File } from '../types';

const processHashLimit = pLimit(5);
const processUploadLimit = pLimit(5);

/**
 *
 * @param dispatch
 * @param id
 * @param setUploadedFiles
 * @param signature
 * @param uploadedFiles
 * @returns
 */
export const useHashAndUpload = (
  dispatch: FileUploadReducerDispatch,
  id: string,
  setUploadedFiles: (files: File[]) => void,
  signature: Signature | undefined,
  uploadedFiles: File[]
) => {
  const [auth] = useAuth();

  const doHashing = async (file: FileWithPath, name: string) => {
    const result = await doHash(file, (progressEvent: any) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      if (progress < 100) {
        dispatch.throttleProgress(name, progress, 'hash');
      }
    });
    // Cancel pending invocation & Set to zero on the upload stage
    dispatch.hash(name, result);
    dispatch.throttleProgressCancel(name);
    dispatch.progress(name, 0, 'upload');
    return result;
  };

  const doUpload = async (
    data: FormData,
    name: string,
    signature: Signature
  ) => {
    await axios.post(signature.url, data, {
      onUploadProgress: (progressEvent: any): void => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        if (progress < 100) {
          dispatch.throttleProgress(name, progress, 'upload');
        }
      }
    });
    // Cancel potentially overriding invocations before progress is finalised
    dispatch.throttleProgressCancel(name);
    dispatch.progress(name, 99, 'upload');
  };

  const doCreateFileRecord = async (
    file: FileWithPath,
    hash: string,
    name: string
  ) => {
    const headers = { Authorization: `Bearer ${auth.token}` };
    const body = {
      filename: name,
      filesize: file.size,
      hash
    };
    await axios.post(POST_FILES, body, { headers });

    dispatch.progress(name, 100, 'upload');

    uploadedFiles.push({
      fileName: name,
      fileSize: file.size,
      hash
    });

    setUploadedFiles(uploadedFiles);
  };

  const hashAndUpload = async (file: FileWithPath, name: string) => {
    if (!signature) {
      return;
    }

    // TODO: Fix that we can't upload bigger than 4GB
    if (file.size > 4e9) {
      const message = 'File is too large. Maximum 4GB allowed';
      const tooBigError = new Error(message);
      dispatch.error(tooBigError, id, name, message);
      return;
    }

    const data = new FormData();
    for (const field in signature.fields) {
      /* eslint-disable security/detect-object-injection */
      data.append(field, signature.fields[field]);
    }
    data.append('Key', id + '/' + name);
    data.append('file', file);

    // This code performs asynchronous operations for hashing, uploading, and creating
    // file records, while also managing rate limits using pLimit package through
    // processHashLimit and processUploadLimit functions.
    let hash: string;
    try {
      hash = await processHashLimit(() => doHashing(file, name));
    } catch (error: any) {
      dispatch.error(error, id, name, 'Error hashing file');
      return;
    }

    try {
      await processUploadLimit(async () => {
        await doUpload(data, name, signature);
        await doCreateFileRecord(file, hash, name);
      });
    } catch (error: any) {
      dispatch.error(error, id, name, 'Error uploading file');
    }
  };

  return hashAndUpload;
};
