import loadable from '@loadable/component';

import { Loading } from '../../molecules';

/**
 *
 */
export const Time = loadable(
  () =>
    import(
      /* webpackChunkName: "time" */
      './time'
    ),
  {
    fallback: Loading,
    resolveComponent: (components) => components.Time
  }
);
