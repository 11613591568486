import { Fragment, ReactNode } from 'react';
import type { FC } from 'react';

import { ErrorBoundary } from '../../molecules';

import { Footer } from './footer/footer';
import { Header } from './header/header';

/**
 *
 * @param props
 */
export const Layout: FC<{ readonly children: ReactNode }> = (props) => (
  <Fragment>
    <Header />
    <ErrorBoundary>{props.children}</ErrorBoundary>
    {Footer}
  </Fragment>
);
