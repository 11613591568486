import type { FC } from 'react';

import styles from './error-notification.module.scss';

/**
 *
 */
export const ErrorNotification: FC = () => (
  <div className={styles.error}>Sorry, something has gone wrong.</div>
);
