import { Component, ReactNode } from 'react';

import { captureError } from '../../../lib/sentry';

import { Card } from '~atoms/card';
import { Container } from '~atoms/container';

interface ErrorBoundaryProps {
  readonly children: ReactNode;
}

interface ErrorBoundaryState {
  error: Error | null;
}

/**
 *
 */
export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  /* eslint-disable handle-callback-err */

  /**
   *
   * @param props
   */
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: null
    };
  }

  /**
   *
   * @param error
   */
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error };
  }

  /**
   *
   * @param error
   * @param errorInfo
   */
  componentDidCatch(error: Error, errorInfo: unknown): void {
    captureError(error, errorInfo);
  }

  /**
   *
   */
  render(): ReactNode {
    if (this.state.error) {
      return (
        <Container>
          <Card>
            <h2>Oops!</h2>
            <p>
              Something has gone wrong that we haven&lsquo;t anticipated. Please
              try again later and if the issue persists please contact&nbsp;
              <a href="mailto:support@safercities.com">
                support@safercities.com
              </a>
              &nbsp;for support.
            </p>
          </Card>
        </Container>
      );
    }

    return this.props.children;
  }
}
