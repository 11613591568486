import { createElement } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '../../components/app';

import '../css/screen.scss';

// Hot reload
if (process.env.NODE_ENV === 'development') {
  (module as any).hot.accept();
}

// Render app
const app = createElement(App);
const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);
root.render(app);
