import { createElement, ReactNode } from 'react';

const newline = /(\r\n|\r|\n)/g;
export const commentDetailsLength = 1024;

/**
 *
 * @param text
 */
export function nl2br(
  text: string | undefined
): Array<ReactNode | string | undefined> {
  if (typeof text !== 'string') {
    return [text];
  }

  return text.split(newline).map((line, key) => {
    return line.match(newline) ? createElement('br', { key }) : line;
  });
}
