import type { ReactElement } from 'react';

import { Container } from '~atoms/container';

/**
 *
 * @param props
 */
export const Footer: ReactElement = (
  <Container>
    <footer className="vault-footer">
      vGRID Vault is developed by{' '}
      <a href="https://safercities.com" rel="noreferrer" target="_blank">
        SaferCities
      </a>
      &nbsp;on behalf of New Zealand Police, for secure collection of evidence.
      <br />
      <br />
      The vGRID platform enables organisations to share live CCTV feeds and ANPR
      with Police or other organisations, to enable better public safety
      outcomes. For more information, please visit the{' '}
      <a href="https://vgrid.io" rel="noreferrer" target="_blank">
        <u>vGRID website</u>
      </a>{' '}
      or contact SaferCities at&nbsp;
      <a href="mailto:support@safercities.com">
        <u>support@safercities.com</u>
      </a>
      .
    </footer>
  </Container>
);
