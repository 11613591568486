import { captureException, configureScope, init } from '@sentry/react';

init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.NODE_ENV === 'production' ? 'prod' : 'dev',
  release: process.env.RELEASE || process.env.GIT_COMMIT || 'dev'
});

/**
 *
 * @param error
 * @param errorInfo
 */
export function captureError(error: Error, errorInfo: any = {}): void {
  configureScope((scope) => {
    for (const key in errorInfo) {
      // eslint-disable-next-line security/detect-object-injection
      scope.setExtra(key, errorInfo[key]);
    }
  });

  captureException(error);
}

/**
 *
 * @param email
 */
export function setUser(email: string): void {
  configureScope((scope) => {
    scope.setUser({ email });
  });
}
