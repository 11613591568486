import dayjs from 'dayjs';
import { Form, Formik, FormikHelpers } from 'formik';
import { Fragment, useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUpload } from '../../context';
import { useUpdateDetails } from '../../hooks';
import { routes } from '../../routes';
import type { Details as DetailsForm } from '../../types';
import { DetailsSchema } from '../details/schema';

import styles from './review.module.scss';

import { Textarea } from '~/components/atoms/input';
import { Button, LinkButton } from '~atoms/button';
import { ButtonBar } from '~atoms/button-bar';
import { Checkbox } from '~atoms/checkbox';
import { ErrorNotification } from '~atoms/error-notification';
import { Section } from '~molecules/section';

/**
 *
 */
export const Review: FC = () => {
  const [isChecked, setChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const { error, updateDetails, completeStatus } = useUpdateDetails();
  const [upload] = useUpload();

  const { details, files } = upload;

  const duration = useMemo(
    () => dayjs.duration({ seconds: upload?.timeDifference || 0 }),
    [upload?.timeDifference]
  );

  const handleSubmit = useCallback(
    async (values: DetailsForm, actions: FormikHelpers<DetailsForm>) => {
      if (isChecked) {
        try {
          const response = await updateDetails(values, actions);

          if (response.data.success) {
            await completeStatus();
            sessionStorage.removeItem('vault-auth');
            navigate(routes.COMPLETE);
          }
        } catch (error) {
          console.error('Error:', error);
        }
      }
    },
    [isChecked, error]
  );

  const toggleDeclaration = useCallback(() => {
    setChecked((checked) => !checked);
  }, []);

  return (
    <Fragment>
      {error ? <ErrorNotification /> : null}
      <Section heading="Personal details">
        <dl className={styles.information}>
          <dt>Name:</dt> <dd>{details.name}</dd>
          <dt>Phone Number:</dt> <dd>{details.phoneNumber}</dd>
          <dt>Email:</dt> <dd>{details.email}</dd>
          <dt>Date of Birth:</dt> <dd>{details.dob}</dd>
        </dl>
      </Section>

      <Section heading="Location details">
        <dl className={styles.information}>
          <dt>Business Name:</dt> <dd>{details.businessName}</dd>
          <dt>Street:</dt> <dd>{details.streetAddress}</dd>
          <dt>City:</dt> <dd>{details.city}</dd>
          <dt>Suburb:</dt> <dd>{details.suburb}</dd>
        </dl>
      </Section>

      <Section heading="Date and time">
        We have calculated that the time difference between your VMS and local
        time is {duration.humanize()}.
      </Section>

      <Section heading="Files">
        <ul className={styles.files}>
          {files.map((file, index) => {
            return (
              <li key={index}>
                <span className={styles.check} />
                {file.fileName}
              </li>
            );
          })}
        </ul>
      </Section>

      <Formik
        initialValues={details}
        onSubmit={handleSubmit}
        validationSchema={DetailsSchema}
      >
        {({ errors }) => (
          <Form>
            <Section heading="Declaration">
              <Textarea id="comment" label="Comment" name="comment" />

              <label className={styles.declaration} htmlFor="declaration">
                <Checkbox
                  checked={isChecked}
                  id="declaration"
                  name="declaration"
                  onChange={toggleDeclaration}
                  value="1"
                />
                <span className={styles.declarationText}>
                  I confirm the truth and accuracy of these details. I make this
                  submission with the knowledge that it may be used in court
                  proceedings.
                  <br />I am aware that it is an offence to provide details or
                  video that is known by me to be false or intended by me to
                  mislead.
                </span>
              </label>
            </Section>

            <ButtonBar>
              <Button type="submit" disabled={!isChecked || !!errors.comment}>
                Confirm and send
              </Button>
              <LinkButton href={routes.FILES} theme="secondary">
                &larr; Back
              </LinkButton>
            </ButtonBar>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
