import type { FC, ReactNode } from 'react';

import { Card } from '~atoms/card';
import { SubHeading } from '~atoms/sub-heading';

interface SectionProps {
  readonly children: ReactNode;
  readonly heading: string;
}

/**
 *
 * @param props
 */
export const Section: FC<SectionProps> = (props) => (
  <section>
    <SubHeading>{props.heading}</SubHeading>
    <Card>{props.children}</Card>
  </section>
);
